import React, { useEffect } from "react";
import { AiFillDatabase } from "react-icons/ai";
import { BiImport } from "react-icons/bi";
import {
  FaBox,
  FaBoxOpen,
  FaCalculator,
  FaFileInvoice,
  FaHome,
  FaMoneyBillWave,
  FaMoneyCheckAlt,
  FaRegCalendarAlt,
  FaStore,
  FaSync,
  FaUsers,
} from "react-icons/fa";
import { GiWideArrowDunk } from "react-icons/gi";
import { GoDatabase } from "react-icons/go";
import { ImUser } from "react-icons/im";
import { IoMdSchool } from "react-icons/io";
import { MdPermContactCalendar, MdSettings } from "react-icons/md";
import { RiShoppingBag3Fill } from "react-icons/ri";

import NavbarTop from "../../components/NavbarTop";
import NavbarLeft from "../../components/NewNavbarLeft";
import {
  ItemsNavDataGroupProps,
  ItemsNavDataProps,
  useSideBar,
} from "../../hook/useSideBar";
import useWindowDimensions from "../../hook/useWindowDimensions";
import * as permissionUser from "../../service/localStorage/permissionUser";
import * as ProviderStorage from "../../service/localStorage/provider";
import * as sellerLocalStorage from "../../service/localStorage/seller";
import { Grid } from "../../styles/global";
import { AlertBillet } from "../AlertBillet";
import { AlertClientUpdate } from "../AlertClientUpdate";

interface LayoutProps {
  component: any;
  props: any;

  admin?: boolean;
  client?: boolean;
  seller?: boolean;
  provider?: boolean;
}

export const brandsSankhya = [400, 300, 23];

export const Layout = ({
  component: Component,
  props,
  admin,
  client,
  seller,
  provider,
}: LayoutProps) => {
  const { handleSetItems, isOpen, handleSetIsOpen } = useSideBar();
  const { width } = useWindowDimensions();

  const permissionUserStorage =
    permissionUser.getUser() as permissionUser.PermissionUserProps;
  const sellerStorage =
    sellerLocalStorage.getUser() as sellerLocalStorage.SellerProps;
  const ProviderData = ProviderStorage.getUser() as ProviderStorage.Provider;

  useEffect(() => {
    var itensSidebar: ItemsNavDataProps[] = [];
    var entity: "" | "/admin" | "/representante" | "/fornecedor" = "";

    if (width < 1366) {
      handleSetIsOpen(false);
    } else {
      handleSetIsOpen(true);
    }

    if (admin) {
      entity = "/admin";
      const {
        order,
        billet,
        devolution,
        indemnity,
        payment,
        scheduling,
        forecast,
        datasheet,
        // collection,
        showFile,
        clientPJ,
        seller,
        provider,
        userAdmin,
        permission,
        requestUpdateClient,
        config,
        import: importPermi,
        devolutionLegacy,
        analytic,
        serviceInvoice,
      } = permissionUserStorage;

      if (order > 1) {
        itensSidebar.push({
          Icon: RiShoppingBag3Fill,
          name: "Carteira de pedidos",
          path: `${entity}/pedidos/situacao/pedidos`,
          isGroup: true,
          isGroupActive: false,
          groupPath: [
            {
              name: "Pedidos",
              path: `${entity}/pedidos/situacao/pedidos`,
            },
            {
              name: "Análise de clientes",
              path: `${entity}/analise/clientes/`,
            },
            // {
            //   name: "Gestão de Carteira RS",
            //   path: `${entity}/enchestes-rs`,
            // },
          ],
        });
      }

      if (billet > 1) {
        itensSidebar.push({
          Icon: FaMoneyBillWave,
          name: "2ª via Boletos",
          path: `${entity}/boletos`,
          isGroup: false,
          isGroupActive: false,
        });
      }

      if (devolution > 1) {
        var itemDevolution: ItemsNavDataProps = {
          Icon: FaBox,
          name: "Devolução",
          path: `${entity}/devolucoes`,
          isGroup: false,
        };

        var groupsPathAdd: ItemsNavDataGroupProps[] = [];

        if (devolution > 3) {
          groupsPathAdd.push(
            {
              name: "Criar",
              path: `${entity}/criar/devolucao`,
            },
            {
              name: "Listar",
              path: `${entity}/devolucoes`,
            }
          );
        }

        if (analytic > 1) {
          groupsPathAdd.push({
            name: "Análises",
            path: `${entity}/analise/devolucao`,
          });
        }

        if (devolutionLegacy > 1) {
          groupsPathAdd.push({
            name: "Legado",
            path: `${entity}/devolucoesLegado`,
          });
        }

        if (config > 1) {
          groupsPathAdd.push(
            {
              name: "Situações",
              path: `${entity}/status/devolucoes`,
            },
            {
              name: "Motivos",
              path: `${entity}/motivo/interno/devolucoes`,
            }
          );
        }

        if (groupsPathAdd.length > 0) {
          itemDevolution = {
            ...itemDevolution,
            isGroup: true,
            isGroupActive: false,
            groupPath: [],
          };

          if (devolution <= 3 && devolution >= 2) {
            groupsPathAdd = [
              {
                name: "Listar",
                path: `${entity}/devolucoes`,
              },
              ...groupsPathAdd,
            ];
          }
        }

        const groupsPathOld: ItemsNavDataGroupProps[] =
          itemDevolution &&
          itemDevolution.groupPath &&
          itemDevolution.groupPath[0]
            ? itemDevolution.groupPath
            : [];

        itemDevolution = {
          ...itemDevolution,
          groupPath: [...groupsPathOld, ...groupsPathAdd],
        };

        itensSidebar.push(itemDevolution);
      }

      if (indemnity > 1) {
        var groupsPathAddIndemnity: ItemsNavDataGroupProps[] = [];
        var itemIndemnity: ItemsNavDataProps = {
          Icon: FaBoxOpen,
          name: "Indenizações",
          path: `${entity}/indenizacaos`,
          isGroup: false,
        };

        if (indemnity > 2) {
          groupsPathAddIndemnity.push(
            {
              name: "Criar",
              path: `${entity}/criar/indenizacao`,
            },
            {
              name: "Listar",
              path: `${entity}/indenizacaos`,
            }
          );
        }

        if (config > 1) {
          groupsPathAddIndemnity.push(
            {
              name: "Situações",
              path: `${entity}/status/indenizacoes`,
            },
            {
              name: "Motivos",
              path: `${entity}/motivo/interno/indenizacoes`,
            }
          );
        }

        if (groupsPathAddIndemnity.length > 0) {
          itemIndemnity = {
            ...itemIndemnity,
            isGroup: true,
            isGroupActive: false,
            groupPath: [],
          };

          if (indemnity < 3) {
            groupsPathAddIndemnity = [
              {
                name: "Listar",
                path: `${entity}/indenizacaos`,
              },
              ...groupsPathAddIndemnity,
            ];
          }
        }

        const groupsPathOld: ItemsNavDataGroupProps[] =
          itemIndemnity && itemIndemnity.groupPath && itemIndemnity.groupPath[0]
            ? itemIndemnity.groupPath
            : [];

        itemIndemnity = {
          ...itemIndemnity,
          groupPath: [...groupsPathOld, ...groupsPathAddIndemnity],
        };

        itensSidebar.push(itemIndemnity);
      }

      if (payment > 1) {
        itensSidebar.push({
          Icon: FaMoneyCheckAlt,
          name: "Negociações",
          path: `${entity}/negociacoes/abertas`,
          isGroup: false,
          isGroupActive: false,
          groupPath: [
            {
              name: "Em aberto",
              path: `${entity}/negociacoes/abertas`,
            },
          ],
        });
      }
      if (serviceInvoice > 1) {
        itensSidebar.push({
          Icon: FaFileInvoice,
          name: "Notas de serviço",
          path: `${entity}/notas-servico`,
          isGroup: false,
          isGroupActive: false,
        });
      }

      if (scheduling > 1) {
        var groupsPathAddScheduling: ItemsNavDataGroupProps[] = [];
        var itemScheduling: ItemsNavDataProps = {
          Icon: FaRegCalendarAlt,
          name: "Agendamento",
          path: `${entity}/calendario`,
          isGroup: true,
          isGroupActive: false,
          groupPath: [
            {
              name: "Calendário",
              path: `${entity}/calendario/`,
            },
            {
              name: "Listar",
              path: `${entity}/agendamentos`,
            },
          ],
        };

        if (analytic > 1) {
          groupsPathAddScheduling.push({
            name: "Análises",
            path: `${entity}/analise/agendamento`,
          });
        }

        if (config > 1) {
          groupsPathAddScheduling.push({
            name: "Situações",
            path: `${entity}/status/agendamentos`,
          });
        }
        const groupsPathOld: ItemsNavDataGroupProps[] =
          itemScheduling &&
          itemScheduling.groupPath &&
          itemScheduling.groupPath[0]
            ? itemScheduling.groupPath
            : [];

        itemScheduling = {
          ...itemScheduling,
          groupPath: [...groupsPathOld, ...groupsPathAddScheduling],
        };

        itensSidebar.push(itemScheduling);
      }

      if (forecast > 1) {
        var itemForecast: ItemsNavDataProps = {
          Icon: GiWideArrowDunk,
          name: "Forecast",
          path: `${entity}/previsoes`,
          isGroup: false,
        };

        if (forecast > 2) {
          itemForecast = {
            ...itemForecast,
            isGroup: true,
            isGroupActive: false,
            groupPath: [
              {
                name: "Criar",
                path: `${entity}/criar/previsao`,
              },
              {
                name: "Listar",
                path: `${entity}/previsoes`,
              },
            ],
          };
        }

        itensSidebar.push(itemForecast);
      }

      if (datasheet > 1) {
        var itemDatasheet: ItemsNavDataProps = {
          Icon: AiFillDatabase,
          name: "Fichas técnicas",
          path: `${entity}/cabecalho-fichas-tecnicas`,
          isGroup: false,
        };

        if (datasheet > 2) {
          itemDatasheet = {
            ...itemDatasheet,
            isGroup: true,
            isGroupActive: false,
            groupPath: [
              {
                name: "Criar",
                path: `${entity}/criar/cabecalho-ficha-tecnica`,
              },
              {
                name: "Listar",
                path: `${entity}/cabecalho-fichas-tecnicas`,
              },
            ],
          };
        }

        var groupsPathAddDatasheet: ItemsNavDataGroupProps[] = [];

        if (config > 1) {
          groupsPathAddDatasheet.push(
            {
              name: "Situação coleção",
              path: `${entity}/status/cabecalho/fichas-tecnicas`,
            },
            {
              name: "Situação Ficha",
              path: `${entity}/status/fichas-tecnicas`,
            }
          );
        }
        const groupsPathOld: ItemsNavDataGroupProps[] =
          itemDatasheet && itemDatasheet.groupPath && itemDatasheet.groupPath[0]
            ? itemDatasheet.groupPath
            : [];

        itemDatasheet = {
          ...itemDatasheet,
          groupPath: [...groupsPathOld, ...groupsPathAddDatasheet],
        };

        itensSidebar.push(itemDatasheet);
      }

      if (showFile > 1) {
        var item: ItemsNavDataProps = {
          Icon: IoMdSchool,
          name: "Tutoriais",
          path: `${entity}/arquivos`,
          isGroup: false,
        };

        if (showFile > 2) {
          item = {
            ...item,
            isGroup: true,
            isGroupActive: false,
            groupPath: [
              {
                name: "Criar",
                path: `${entity}/criar/arquivo`,
              },
              {
                name: "Listar",
                path: `${entity}/arquivos`,
              },
            ],
          };
        }

        itensSidebar.push(item);
      }

      if (
        clientPJ > 1 ||
        seller > 1 ||
        provider > 1 ||
        userAdmin > 1 ||
        permission > 1
      ) {
        if (requestUpdateClient > 1) {
          itensSidebar.push({
            Icon: FaSync,
            isGroup: false,
            name: "Atualização cadastral",
            path: `${entity}/atualizacao-fotos?`,
          });
        }

        var itemUsers: ItemsNavDataProps = {
          Icon: FaUsers,
          name: "Usuários",
          path: "#",
          isGroup: true,
          isGroupActive: false,
          groupPath: [],
        };

        if (clientPJ > 1) {
          itemUsers.groupPath?.push({
            name: "Clientes",
            path: `${entity}/clientes`,
          });
        }
        if (seller > 1) {
          itemUsers.groupPath?.push({
            name: "Representantes",
            path: `${entity}/representantes`,
          });
        }
        if (provider > 1) {
          itemUsers.groupPath?.push({
            name: "Fornecedor",
            path: `${entity}/fornecedores`,
          });
        }
        if (userAdmin > 1) {
          itemUsers.groupPath?.push({
            name: "Operador",
            path: `${entity}/usuarios`,
          });
        }
        if (permission > 1) {
          itemUsers.groupPath?.push({
            name: "Permissões",
            path: `${entity}/permissoes`,
          });
        }

        if (analytic > 1) {
          itemUsers.groupPath?.push({
            name: "Análises acessos",
            path: `${entity}/analise/acessos`,
          });

          itemUsers.groupPath?.push({
            name: "Análise cadastral",
            path: `${entity}/analise/atualizacao-cadastral`,
          });
        }

        itensSidebar.push(itemUsers);
      }

      itensSidebar.push({
        Icon: GoDatabase,
        name: "Dados",
        path: "#",
        isGroup: true,
        isGroupActive: false,
        groupPath: [
          { name: "Cores", path: `${entity}/cores/` },
          { name: "NCMs", path: `${entity}/ncms/` },
          { name: "Contatos", path: `${entity}/contatos/` },
          { name: "Banners", path: `${entity}/banners/` },
        ],
      });

      itensSidebar.push({
        Icon: FaCalculator,
        name: "Calculadora vendor",
        path: `${entity}/calculadora-vendor`,
        isGroup: false,
      });

      if (config > 2) {
        itensSidebar.push({
          Icon: MdSettings,
          name: "Configurações",
          path: "#",
          isGroup: true,
          isGroupActive: false,
          groupPath: [
            {
              name: "Marca",
              path: `${entity}/marcas`,
            },
            {
              name: "Representantes",
              path: `${entity}/preechimento/representantes`,
            },
            {
              name: "Supervisor",
              path: `${entity}/supervisores`,
            },
            {
              name: "Transportadora",
              path: `${entity}/transportadoras`,
            },
            {
              name: "Coleções",
              path: `${entity}/descricoes/forecast`,
            },
            {
              name: "Gateway de Pagamento",
              path: `${entity}/configuracoes/gatewayspagamento`,
            },
            {
              name: "Gestão de pedidos",
              path: `${entity}/configuracoes/pedidos`,
            },
            {
              name: "Calculadora Vendor",
              path: `${entity}/configuracoes/calculadora`,
            },
          ],
        });
      }

      if (importPermi > 2) {
        itensSidebar.push({
          Icon: BiImport,
          name: "Importações",
          path: "#",
          isGroup: true,
          isGroupActive: false,
          groupPath: [
            { name: "Clientes", path: `${entity}/importacao/cliente` },
            { name: "2ª via Boletos", path: `${entity}/importacao/boleto` },
            { name: "Marcas", path: `${entity}/importacao/marca` },
            {
              name: "Representantes preechimento",
              path: `${entity}/importacao/preechimento/representante`,
            },
            {
              name: "Representantes",
              path: `${entity}/importacao/representante`,
            },
            {
              name: "Marcas do Representantes",
              path: `${entity}/importacao/representantemarcas`,
            },
            { name: "Supervisor", path: `${entity}/importacao/supervisor` },
            {
              name: "Transportadora",
              path: `${entity}/importacao/transportadoras`,
            },
            {
              name: "Motivo Devolução",
              path: `${entity}/importacao/motivo/interno/devolucao`,
            },
            {
              name: "Motivo Indenização",
              path: `${entity}/importacao/motivo/interno/indenizacao`,
            },
            {
              name: "Cores",
              path: `${entity}/importacao/cores`,
            },
          ],
        });
      }

      itensSidebar = [
        {
          Icon: FaHome,
          name: "Página inicial",
          path: `${entity}/home`,
          isGroup: false,
        },
        ...itensSidebar,
      ];
    }
    if (client) {
      entity = "";

      itensSidebar = [
        {
          Icon: FaHome,
          name: "Página inicial",
          path: `${entity}/home`,
          isGroup: false,
        },
        {
          Icon: RiShoppingBag3Fill,
          name: "Carteira de pedidos",
          path: `${entity}/pedidos/situacao/pedidos`,
          isGroup: true,
          isGroupActive: false,
          groupPath: [
            {
              name: "Pedidos",
              path: `${entity}/pedidos/situacao/pedidos`,
            },
          ],
        },

        {
          Icon: FaMoneyBillWave,
          name: "2ª via Boletos",
          path: `${entity}/boletos`,
          isGroup: false,
          isGroupActive: false,
        },
        {
          Icon: FaBox,
          name: "Devolução",
          path: `${entity}/devolucoes`,
          isGroup: true,
          isGroupActive: false,
          groupPath: [
            {
              name: "Criar",
              path: `${entity}/criar/indenizacaoOuDevolucao`,
            },
            {
              name: "Listar",
              path: `${entity}/devolucoes`,
            },
          ],
        },
        {
          Icon: FaBoxOpen,
          name: "Indenizações",
          path: `${entity}/indenizacaos`,
          isGroup: true,
          isGroupActive: false,
          groupPath: [
            {
              name: "Criar",
              path: `${entity}/criar/indenizacaoOuDevolucao`,
            },
            {
              name: "Listar",
              path: `${entity}/indenizacaos`,
            },
          ],
        },
        {
          Icon: FaMoneyCheckAlt,
          name: "Negociações",
          path: `${entity}/negociacoes/abertas`,
          isGroup: false,
          isGroupActive: false,
          groupPath: [
            {
              name: "Em aberto",
              path: `${entity}/negociacoes/abertas`,
            },
          ],
        },

        // {
        //   Icon: FaTruck,
        //   name: "Rastreamento",
        //   path: `${entity}/rastreamento`,
        //   isGroup: false,
        // },
      ];
    }
    if (seller) {
      entity = "/representante";

      var orders: ItemsNavDataProps[] = [];

      orders.push({
        Icon: RiShoppingBag3Fill,
        name: "Carteira de pedidos",
        path: `${entity}/pedidos/situacao/pedidos`,
        isGroup: true,
        isGroupActive: false,
        groupPath: [
          {
            name: "Pedidos",
            path: `${entity}/pedidos/situacao/pedidos`,
          },
          {
            name: "Análise de clientes",
            path: `${entity}/analise/clientes/`,
          },
          // {
          //   name: "Gestão de Carteira RS",
          //   path:
          //     sellerStorage.is_manager || sellerStorage.is_supervisor
          //       ? `${entity}/manager/enchestes-rs`
          //       : `${entity}/enchestes-rs`,
          // },
        ],
      });

      itensSidebar = [
        {
          Icon: FaHome,
          name: "Página inicial",
          path: `${entity}/home`,
          isGroup: false,
        },

        ...orders,

        {
          Icon: FaMoneyBillWave,
          name: "2ª via Boletos",
          path: `${entity}/boletos`,
          isGroup: false,
          isGroupActive: false,
        },
      ];

      if (!sellerStorage.is_manager) {
        itensSidebar.push({
          Icon: FaBox,
          name: "Devolução",
          path: `${entity}/devolucoes`,
          isGroup: false,
        });
      }

      if (sellerStorage.is_manager) {
        itensSidebar.push(
          {
            Icon: FaBox,
            name: "Devolução",
            path: `${entity}/devolucoes`,
            isGroup: true,
            isGroupActive: false,
            groupPath: [
              {
                name: "Listar",
                path: `${entity}/devolucoes`,
              },
              {
                name: "Análises",
                path: `${entity}/analise/devolucao`,
              },
            ],
          },
          {
            Icon: FaRegCalendarAlt,
            name: "Agendamento",
            path: `${entity}/calendario`,
            isGroup: true,
            isGroupActive: false,
            groupPath: [
              {
                name: "Calendário",
                path: `${entity}/calendario/`,
              },
              {
                name: "Listar",
                path: `${entity}/agendamentos`,
              },
              { name: "Análises", path: `${entity}/analise/agendamento` },
            ],
          },
          {
            Icon: ImUser,
            name: "Representantes",
            path: `${entity}/representantes`,
            isGroup: false,
            isGroupActive: false,
          }
        );
      }

      if (sellerStorage.is_supervisor) {
        itensSidebar.push({
          Icon: ImUser,
          name: "Representantes",
          path: `${entity}/representantes`,
          isGroup: false,
          isGroupActive: false,
        });
      }

      itensSidebar.push(
        {
          Icon: GiWideArrowDunk,
          name: "Forecast",
          path: `${entity}/previsoes`,
          isGroup: false,
        },
        {
          Icon: FaStore,
          name: "Clientes",
          path: `${entity}/clientes`,
          isGroup: true,
          isGroupActive: false,
          groupPath: [
            {
              name: "Listar clientes",
              path: `${entity}/clientes`,
            },
            {
              name: "Atualizações cadastral",
              path: `${entity}/atualizacao-fotos`,
            },
          ],
        },
        {
          Icon: FaFileInvoice,
          name: "Notas de serviço",
          path: `${entity}/notas-servico`,
          isGroup: false,
          isGroupActive: false,
        },
        {
          Icon: IoMdSchool,
          name: "Tutoriais",
          path: `${entity}/arquivos`,
          isGroup: false,
        },
        {
          Icon: FaCalculator,
          name: "Calculadora vendor",
          path: `${entity}/calculadora-vendor`,
          isGroup: false,
        },
        {
          Icon: MdPermContactCalendar,
          name: "Contatos",
          path: `${entity}/contatos`,
          isGroup: false,
        }
      );
    }
    if (provider) {
      entity = "/fornecedor";
      itensSidebar = [
        {
          Icon: FaHome,
          name: "Página inicial",
          path: `${entity}/home`,
          isGroup: false,
        },
        ProviderData?.isShippingCompany
          ? {
              Icon: FaBox,
              name: "Devolução",
              path: `${entity}/devolucoes`,
              isGroup: true,
              isGroupActive: false,
              groupPath: [
                {
                  name: "Criar",
                  path: `${entity}/criar/devolucao`,
                },
                {
                  name: "Listar",
                  path: `${entity}/devolucoes`,
                },
              ],
            }
          : {
              Icon: FaRegCalendarAlt,
              name: "Agendamento",
              path: `${entity}/calendario`,
              isGroup: true,
              isGroupActive: false,
              groupPath: [
                {
                  name: "Calendário",
                  path: `${entity}/calendario/`,
                },
                {
                  name: "Listar",
                  path: `${entity}/agendamentos`,
                },
              ],
            },
      ];
    }

    handleSetItems(itensSidebar);
    // eslint-disable-next-line
  }, [admin, client, seller, provider]);

  return (
    <>
      {client && <AlertBillet />}
      {client && <AlertClientUpdate />}
      {/* {client && <AlertDevolution />} */}
      {/* {client && <AlertEnchenteRs />} */}

      <Grid isClient={!!client} isOpenNav={isOpen}>
        <NavbarTop
          admin={admin}
          permission={permissionUserStorage}
          seller={seller}
          is_manager={sellerStorage?.is_manager ? true : false}
          is_supervisor={sellerStorage?.is_supervisor ? true : false}
          client={client}
          provider={provider}
        />
        <NavbarLeft
          admin={admin}
          permission={permissionUserStorage}
          seller={seller}
          is_manager={sellerStorage?.is_manager ? true : false}
          is_supervisor={sellerStorage?.is_supervisor ? true : false}
          client={client}
          provider={provider}
        />
        <Component
          admin={admin}
          permission={permissionUserStorage}
          seller={seller}
          is_manager={sellerStorage?.is_manager ? true : false}
          is_supervisor={sellerStorage?.is_supervisor ? true : false}
          client={client}
          provider={provider}
          {...props}
        />
      </Grid>
    </>
  );
};
