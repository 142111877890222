import React from "react";
import { IconType } from "react-icons";
import { BiReceipt } from "react-icons/bi";
import { FcClock } from "react-icons/fc";
import { HiBadgeCheck } from "react-icons/hi";
import { RiAlertFill, RiCloseCircleFill } from "react-icons/ri";
// import { TbProgressCheck } from "react-icons/tb";
import { UseQueryResult, useQuery } from "react-query";
import { OptionsFilter } from "../../@types";
import { ResponsePanelApiDefault } from "../../@types/panelTypes";
import { CheckProgress } from "../../assets/icons/CheckProgress";
import api from "../../service/api";

export type Order = {
  id: number;
  cod: number;
  initialsOrder: string | null;
  noteValue: number;
  merchandiseValue: number;
  top: number;
  species?: number;
  deliveryDate: Date;
  billingDate: Date;
  paymentCondition: string;
  position: string;
  documentNumber: string;
  detailPosition?: string;
  typeAndBillingDate?: string;

  pointedCod?: string;
  pointedDesc?: string;
  refuseCod?: string;
  refuseDesc?: string;

  status?: {
    name: string;
    description: string;
    order_position_filter?: {
      name: string;
      description: string;
    };

    isAwaitValid: boolean;
    isSuccessValid: boolean;
    isAlertValid: boolean;
    isNoteValid: boolean;
    isErrorValid: boolean;
    isPartialSuccessValid: boolean;
    Icon: JSX.Element | null;
    color: string;
  };

  clients_pj?: {
    cod?: Number;
    cnpj?: String;
    phone?: String;
    company_name?: string;
    email?: string;
    trade_name?: String;
    adresses?: {
      city?: String;
      uf?: String;
    };
  };

  seller?: {
    cod?: number;
    abbreviation?: string;
    fullName?: string;
    email?: string;
  };

  brands?: {
    cod?: number;
    name?: string;
    is_not_show_delivery_at?: boolean;
  };

  order_products?: {
    status: string;
    quantity: string;
    value: string;
    totalValue: string;
    documentNumber?: string;
    invoiceDate?: string;
    currentOrderCod?: number;

    product: {
      cod: string;
      reference: string;
      description: string;
      grid: string;
    };
  }[];
};

export type OrderFilters = {
  cod?: string;
  documentNumber?: string;
  status?: string;

  idClient?: number;
  codClient?: number;
  cnpj?: string;
  company_name?: string;

  type?: string;
  pointedDesc?: string;

  codSeller?: string;
  brand?: string;

  refuse?: number;
  highlighter?: string;

  orderPeriod?: number;
  orderPresetDate?: number;
  orderSpecificDate?: Date;
  orderStartDate?: Date;
  orderEndDate?: Date;

  billingOrderPeriod?: number;
  billingOrderPresetDate?: number;
  billingOrderSpecificDate?: Date;
  billingOrderStartDate?: Date;
  billingOrderEndDate?: Date;

  orderBy?: string;
  isReport?: boolean;
};

export type GetOrdersResponse = {
  orders: Order[];
  filters: FilterOptions;
  totalCount: number;
  lastUpdateToString: string;
  registersPerPage: number;
};

export interface FilterOptions {
  status: OptionsFilter[];
  brands: OptionsFilter[];
  pointeds: OptionsFilter[];
  refuses: OptionsFilter[];
  sellers: OptionsFilter[];
  highlighters: OptionsFilter[];
}

interface OrderApiResponse extends ResponsePanelApiDefault {
  contents: Order[];
  filters: FilterOptions;
  lastUpdate: Date | undefined;
}

export const positionValid = {
  awaitValid: [
    "aguardando-atendimento",
    "atendido-parcialmente",
    "credito",
    "Bloqueado",
  ],
  successValid: ["atendido", "Duplicata emitida", "Faturado"],
  alertValid: ["credito-recusado", "recusado", "Recusado"],
  noteValid: ["faturado"],
  errorValid: ["cancelado", "Cancelado"],
  partialSuccessValid: ["Bloqueado parcial", "Parcialmente faturado"],
};

const icons = {
  alert: {
    Icon: RiAlertFill,
    size: 26,
    color: "#9700C2",
  },
  wait: {
    Icon: FcClock,
    size: 26,
    color: "#417DAB",
  },

  success: {
    Icon: HiBadgeCheck,
    size: 26,
    color: "#21b543",
  },
  partialSuccess: {
    Icon: CheckProgress,
    size: 22,
    color: "#417DAB",
  },

  note: {
    Icon: BiReceipt,
    size: 26,
    color: "#417DAB",
  },

  error: {
    Icon: RiCloseCircleFill,
    size: 26,
    color: "#d21e26",
  },
};

export function selectIcon(status: string): JSX.Element | null {
  var icon: { Icon: IconType; size: number; color?: string } | undefined =
    icons.wait;

  if (positionValid.awaitValid.includes(status ?? "")) icon = icons.wait;
  if (positionValid.successValid.includes(status ?? "")) icon = icons.success;
  if (positionValid.alertValid.includes(status ?? "")) icon = icons.alert;
  if (positionValid.noteValid.includes(status ?? "")) icon = icons.note;
  if (positionValid.errorValid.includes(status ?? "")) icon = icons.error;
  if (positionValid.partialSuccessValid.includes(status ?? ""))
    icon = icons.partialSuccess;

  return icon !== undefined ? (
    <icon.Icon size={icon.size} color={icon.color} />
  ) : null;
}

export function selectColor(status: string): string {
  var icon: { Icon: IconType; size: number; color?: string } | undefined =
    icons.wait;

  if (positionValid.awaitValid.includes(status ?? "")) icon = icons.wait;
  if (positionValid.successValid.includes(status ?? "")) icon = icons.success;
  if (positionValid.alertValid.includes(status ?? "")) icon = icons.alert;
  if (positionValid.noteValid.includes(status ?? "")) icon = icons.note;
  if (positionValid.errorValid.includes(status ?? "")) icon = icons.error;
  if (positionValid.partialSuccessValid.includes(status ?? ""))
    icon = icons.partialSuccess;

  return icon.color ?? "";
}

export const normalizedDateTypeAndBillingDate = (date: Date) => {
  const year = new Date(date).toLocaleString("pt-br", {
    year: "numeric",
  });
  const month = new Date(date).toLocaleString("pt-br", {
    month: "long",
  });

  return `${month.toUpperCase()} ${year}`;
};

export async function getOrders(
  page: number,
  filter?: OrderFilters,
  registersPerPag?: number
): Promise<GetOrdersResponse> {
  const { data } = await api.get<OrderApiResponse>("/orderSankhya", {
    params: {
      pageRequest: page - 1,
      limitRequest: registersPerPag,
      ...filter,
      position: "pedidos",
    },
  });

  const normalized = data.contents.map((order) => ({
    ...order,
    typeAndBillingDate: `${
      order.species === 9 ? "VF" : "PE"
    } ${normalizedDateTypeAndBillingDate(order.billingDate)}`,
    status: {
      ...order.status,
      Icon: selectIcon(String(order?.status?.name ?? "")),
      color: selectColor(order?.status?.name ?? ""),
      isAwaitValid: positionValid.awaitValid.includes(
        order?.status?.name ?? ""
      ),
      isSuccessValid: positionValid.successValid.includes(
        order?.status?.name ?? ""
      ),
      isAlertValid: positionValid.alertValid.includes(
        order?.status?.name ?? ""
      ),
      isNoteValid: positionValid.noteValid.includes(order?.status?.name ?? ""),
      isErrorValid: positionValid.errorValid.includes(
        order?.status?.name ?? ""
      ),
      isPartialSuccessValid: positionValid.partialSuccessValid.includes(
        order?.status?.name ?? ""
      ),
    },
  })) as Order[];

  return {
    totalCount: data.total,
    orders: normalized,
    filters: data.filters,
    lastUpdateToString: data.lastUpdate
      ? new Date(data.lastUpdate).toLocaleString("pt-br", {
          day: "numeric",
          month: "long",
          year: "numeric",
          hour: "2-digit",
          minute: "2-digit",
        })
      : "-",

    registersPerPage: data.limit,
  };
}

export function useOrders(
  page: number,
  filter?: OrderFilters,
  registersPerPag?: number
): UseQueryResult<GetOrdersResponse> {
  return useQuery(
    ["orders", page, filter],
    () => getOrders(page, filter, registersPerPag),
    {
      staleTime: 1000 * 60 * 10, // 10 Minutos
    }
  );
}

export async function getOneOrder(id?: number): Promise<Order | undefined> {
  if (!id && id !== 0) return undefined;

  const { data } = await api.get<Order>(`/populate/Orders/show/${id}`);

  return data;
}

export function useOneOrder(id?: number): UseQueryResult<Order> | undefined {
  return useQuery(["Order", id], () => getOneOrder(id), {
    staleTime: 1000 * 60 * 10, // 10 Minutos
  });
}
