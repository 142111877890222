import filesize from "filesize";
import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useToasts } from "react-toast-notifications";
import * as Yup from "yup";

import icon_pdf from "../../../assets/icon_pdf.png";
import icon_xml from "../../../assets/icon_xml.png";
import { setFormikValues } from "../../../utils/setFormikValues";

import api from "../../../service/api";

import { FaCheckCircle } from "react-icons/fa";
import { FiAlertCircle } from "react-icons/fi";
import { IoIosSend, IoMdTrash } from "react-icons/io";
import { MdOutlineFileDownload } from "react-icons/md";
import { IPermissionUser } from "../../../@types";
import { Accordion } from "../../../components/Accordion";
import { Box, BoxGroup, BoxHeading } from "../../../components/Box";
import { CreateAndDetailLayout } from "../../../components/CreateAndDetailLayout";
import { ButtonCheck } from "../../../components/Form/ButtonCheck";
import { ButtonSubmit } from "../../../components/Form/ButtonSubmit";
import { GroupInput } from "../../../components/Form/GroupInput";
import { Input } from "../../../components/Form/Input";
import { LayoutForm } from "../../../components/Form/LayoutForm";
import { Textarea } from "../../../components/Form/Textarea";
import { InfoCards } from "../../../components/InfoCards";
import Loading from "../../../components/Loading";
import Modal from "../../../components/ModalLib";
import { PanelAndDetailAndCreateHeader } from "../../../components/panel/PanelAndDetailAndCreateHeader";
import { TableContent } from "../../../components/panel/table/TableContent";
import Dropzone from "../../../components/Upload/DropzoneArr";
import {
  Invoice,
  Release,
  useOneServiceInvoice,
} from "../../../hook/queries/useServiceInvoice";
import { queryClient } from "../../../service/queryClient";
import { downloadFilaBase64 } from "../../../utils/downloadFilaBase64";
import { ContainerPreviews } from "../../create/styles";
import { Error as ContainerError, PopUpConfirmation } from "../styles";

interface HomeProps {
  admin?: boolean;
  seller?: boolean;
  permission?: IPermissionUser;

  match: {
    params: {
      id: number;
    };
  };
}

const ServiceInvoice: React.FC<HomeProps> = ({
  match,
  seller,
  admin,
  permission,
}) => {
  const getServiceInvoice = useOneServiceInvoice(match.params.id);
  const { addToast } = useToasts();
  const history = useHistory();

  const [loading, setLoading] = useState(true);
  const [loadingForm, setLoadingForm] = useState(false);
  const [loadingSendInvoiceAlert, setLoadingSendInvoiceAlert] = useState(false);

  const [modalSendInvoiceAlert, setModalSendInvoiceAlert] = useState(false);
  const [modalApprovalAlert, setModalApprovalAlert] = useState(false);

  const formik = useFormik<{
    invoicePdf?: File;
    invoiceXml?: File;
  }>({
    initialValues: {
      invoicePdf: undefined,
      invoiceXml: undefined,
    },

    validationSchema: Yup.object({
      invoicePdf: Yup.mixed().required("Arquivo PDF é obrigatório"),
      invoiceXml: Yup.mixed().required("Arquivo XML é obrigatório"),
    }),

    onSubmit: (values) => handleSubmit(values as any),
  });

  const formikApproval = useFormik({
    initialValues: {
      statusCod: undefined,
      reason: undefined,
    },

    validationSchema: Yup.object({
      statusCod: Yup.string().required("Situação é obrigatório"),
    }),

    onSubmit: (values) => handleSubmitApproval(values as any),
  });

  useEffect(() => {
    if (getServiceInvoice?.isSuccess && getServiceInvoice?.data) {
      setFormikValues(getServiceInvoice?.data, formik, setLoading);
    } else {
      setLoading(true);
    }
    // eslint-disable-next-line
  }, [getServiceInvoice?.data, getServiceInvoice?.isSuccess]);

  async function handleSubmit(updates: { invoicePdf: File; invoiceXml: File }) {
    setLoadingForm(true);

    try {
      const formDataXml = new FormData();
      formDataXml.append("file", updates.invoiceXml);
      await api.post(
        `/service-invoice/upload-xml/${match.params.id}`,
        formDataXml
      );

      const formDataPdf = new FormData();
      formDataPdf.append("file", updates.invoicePdf);
      await api.post(
        `/service-invoice/upload-pdf/${match.params.id}`,
        formDataPdf
      );

      await api.put(`/service-invoice/${match.params.id}`, {
        statusCod: 2,
      });

      addToast("Nota de serviço enviadas para aprovação.", {
        appearance: "success",
        autoDismiss: true,
      });
      queryClient.invalidateQueries("service-invoices");
      history.goBack();
    } catch (err) {
      const error = err as any;

      if (!error.response) {
        return addToast(
          "Desculpe, ocorreu um erro interno, Tente novamente mais tarde",
          {
            appearance: "error",
            autoDismiss: true,
          }
        );
      }

      if (error.response.status === 401) {
        return addToast("Sem permissão para esta ação", {
          appearance: "warning",
          autoDismiss: true,
        });
      }

      if (error.response.data.error === "email already exists") {
        return addToast("Este e-mail já existe em nossa base de dados", {
          appearance: "warning",
          autoDismiss: true,
        });
      }

      addToast(
        "Desculpe, ocorreu um erro interno, Tente novamente mais tarde",
        {
          appearance: "error",
          autoDismiss: true,
        }
      );
    } finally {
      setLoadingForm(false);
    }
  }

  async function handleSubmitApproval(updates: {
    statusCod: number;
    reason?: string;
  }) {
    setLoadingForm(true);
    try {
      await api.put(`/service-invoice/${match.params.id}`, {
        statusCod: Number(updates.statusCod),
        reason: updates.reason,
      });

      addToast("Nota de serviço enviadas para aprovação.", {
        appearance: "success",
        autoDismiss: true,
      });
      history.goBack();
      queryClient.invalidateQueries("service-invoices");
    } catch (err) {
      const error = err as any;

      if (error.response.status === 401) {
        return addToast("Sem permissão para esta ação", {
          appearance: "warning",
          autoDismiss: true,
        });
      }

      return addToast(
        "Desculpe, ocorreu um erro interno, Tente novamente mais tarde",
        {
          appearance: "error",
          autoDismiss: true,
        }
      );
    } finally {
      setLoadingForm(false);
    }
  }

  async function handleDownloadFile({
    key,
    filename,
    mimetype,
  }: {
    key?: string;
    filename: string;
    mimetype: string;
  }) {
    addToast(`Gerando arquivo...`, {
      appearance: "info",
      autoDismiss: true,
      autoDismissTimeout: 10000,
    });

    try {
      const getFileBase64 = await api.post(`forecast/file/getFileBase64`, {
        key: key,
      });

      downloadFilaBase64({
        base64: getFileBase64.data ?? "",
        filename: filename,
        mimetype: mimetype ?? "",
      });
    } catch (error) {
      return addToast(
        "Ocorreu um erro ao gerar arquivo, Tente novamente mais tarde ou entre em contato conosco (51) 3441-1000",
        {
          appearance: "error",
          autoDismiss: true,
        }
      );
    }
  }

  async function handleSendInvoiceAlert() {
    setLoadingSendInvoiceAlert(true);

    try {
      await api.post(`/service-invoice/mail/${getServiceInvoice?.data?.id}`);
      setModalSendInvoiceAlert(false);
    } catch (error) {
      console.log(error);
    } finally {
      setLoadingSendInvoiceAlert(false);
    }
  }
  async function handleApprovalInvoiceAlert() {
    setLoadingSendInvoiceAlert(true);

    try {
      await api.put(`/service-invoice/${match.params.id}`, { statusCod: 1 });
      queryClient.invalidateQueries("service-invoices");
      history.goBack();
      setModalSendInvoiceAlert(false);
    } catch (error) {
      console.log(error);
    } finally {
      setLoadingSendInvoiceAlert(false);
    }
  }

  return (
    <>
      <CreateAndDetailLayout
        isLoading={getServiceInvoice?.isLoading || loading}
      >
        <PanelAndDetailAndCreateHeader title={"Nota de serviço"} goBack>
          <div className="containerActions">
            {!seller && getServiceInvoice?.data?.status.cod !== 5 && (
              <button
                type="button"
                className="buttonActionDatasheet"
                onClick={() => {
                  setModalSendInvoiceAlert(true);
                }}
              >
                {
                  <div>
                    <span>
                      <IoIosSend color="rgb(2, 106, 166)" />
                    </span>
                    Enviar email de alerta
                  </div>
                }
              </button>
            )}

            {!seller && getServiceInvoice?.data?.status.cod === 5 && (
              <button
                type="button"
                className="buttonActionDatasheet"
                onClick={() => {
                  setModalApprovalAlert(true);
                }}
              >
                {
                  <div>
                    <span>
                      <FaCheckCircle color="#1b9f03" />
                    </span>
                    Aprovar
                  </div>
                }
              </button>
            )}
          </div>
        </PanelAndDetailAndCreateHeader>

        <InfoCards
          cards={[
            {
              title: "Código",
              value: getServiceInvoice?.data?.id ?? "-",
            },
            {
              title: "Situação",
              value: getServiceInvoice?.data?.status.name ?? "-",
              colorLine: getServiceInvoice?.data?.status.color,
            },
            {
              title: "Tipo",
              value: getServiceInvoice?.data?.typeNormalized ?? "-",
            },
            {
              title: "Período",
              value: getServiceInvoice?.data?.periodNormalized ?? "-",
            },
          ].filter((f) =>
            !getServiceInvoice?.data?.periodNormalized
              ? f.title !== "Período"
              : true
          )}
        />

        <BoxGroup>
          {!seller && (
            <Box>
              <BoxHeading>Representante</BoxHeading>

              <Input
                label="Código"
                value={getServiceInvoice?.data?.seller.cod}
                readOnly
              />
              <Input
                label="Abreviação"
                value={getServiceInvoice?.data?.seller.abbreviation}
                readOnly
              />
            </Box>
          )}

          {!!getServiceInvoice?.data?.operador && (
            <Box>
              <BoxHeading>Repensável</BoxHeading>

              <Input
                label="Nome"
                value={
                  !!getServiceInvoice?.data?.operador
                    ? `${getServiceInvoice?.data?.operador?.name} ${getServiceInvoice?.data?.operador?.lastname}`
                    : "-"
                }
                readOnly
              />
              <Input
                label="Email"
                value={getServiceInvoice?.data?.operador?.email ?? "-"}
                readOnly
              />
            </Box>
          )}
        </BoxGroup>

        {getServiceInvoice?.data?.type === "comissao" && (
          <Box style={{ marginBottom: "1rem" }}>
            <BoxHeading>Resumo</BoxHeading>

            <GroupInput>
              <Input
                label="Percentual comissão"
                value={getServiceInvoice.data.commissionPercetageNormalized}
                readOnly
              />
            </GroupInput>

            <GroupInput>
              <Input
                label="Valor venda"
                value={getServiceInvoice.data.salePriceNormalized}
                readOnly
              />
              <Input
                label="Valor comissão"
                value={getServiceInvoice.data.commissionValueNormalized}
                readOnly
              />
              <Input
                label="Valor Dev/Incob"
                value={getServiceInvoice.data.returnValueNormalized}
                readOnly
              />
              <Input
                label="Comissão estornada"
                value={getServiceInvoice.data.commissionRefundedNormalized}
                readOnly
              />
              <Input
                label="Saldo Venda"
                value={getServiceInvoice.data.saleBalanceNormalized}
                readOnly
              />
              <Input
                label="Base IRRF"
                value={getServiceInvoice.data.IRRFBaseNormalized}
                readOnly
              />
              <Input
                label="Saldo Comissão"
                value={getServiceInvoice.data.comissionBalanceNormalized}
                readOnly
              />
              <Input
                label="IRF"
                value={getServiceInvoice.data.IRFValueNormalized}
                readOnly
              />
              <Input
                label="Liquido"
                value={getServiceInvoice.data.liquidComissionNormalized}
                readOnly
              />
              <Input
                label="Saldo lançamentos"
                value={getServiceInvoice.data.releasesBalanceNormalized}
                readOnly
              />
            </GroupInput>

            {(getServiceInvoice.data.releases.length ?? 0) > 0 && (
              <>
                <h3>Lançamentos</h3>
                <TableContent<Release>
                  data={getServiceInvoice.data.releases}
                  disabledHover
                  columns={[
                    {
                      name: "description",
                      field: "DESCRIÇÃO",
                      viewList: true,
                    },
                    {
                      name: "valueNormalized",
                      field: "VALOR",
                      viewList: true,
                    },
                  ]}
                />
              </>
            )}

            <h3>Documentos</h3>

            <div style={{ marginBottom: "1rem" }}>
              {getServiceInvoice.data.invoices.map((type) => (
                <Accordion
                  isOpenDefault={false}
                  title={`${type.typeNormalized} (${type.totalFormat})`}
                  key={type.type}
                  headerStyles={{
                    background: "#f0f0f0",
                    borderRadius: 6,
                    marginTop: "1rem",
                    color: type.type === "duplicata" ? "#21b543" : "#d21e26",
                  }}
                  bodyStyles={{
                    background: "#f0f0f0",
                    borderRadius: "0 0 6px 6px",
                  }}
                >
                  <div style={{ padding: "1rem" }}>
                    {type.clients.map((client) => (
                      <Accordion
                        isOpenDefault={false}
                        title={`${
                          client.client.company_name ?? "CLIENTE NÃO CADASTRADO"
                        } - ${client.totalFormat}`}
                        size="sm"
                        headerStyles={{
                          marginTop: "1rem",
                        }}
                        bodyStyles={{
                          background: "#fff",
                          borderRadius: "0 0 6px 6px",
                          padding: "0.5rem",
                        }}
                      >
                        <TableContent<Invoice>
                          data={client.invoice}
                          disabledHover
                          columns={[
                            {
                              name: "sigla",
                              field: "SIGLA",
                              viewList: true,
                            },
                            {
                              name: "numberNf",
                              field: "NOSSO PEDIDOS",
                              render: (item) => `NE/${item.invoiceNumber}`,
                              viewList: true,
                            },
                            {
                              name: "numberInvoice",
                              field: "DUPLICATA",
                              render: (item) =>
                                `${item.documentNumber}-${item.sequence ?? 0}`,
                              viewList: true,
                            },
                            {
                              name: "emissionDateNormalized",
                              field: "DATA EMISSÃO",
                              viewList: true,
                            },
                            {
                              name: "dueDateNormalized",
                              field: "DATA PAGAMENTO",
                              viewList: true,
                            },

                            {
                              name: "commissionPercetage",
                              field: "PERCENTUAL COMISSÃO",
                              viewList: true,
                              render: (item) => `${item.commissionPercetage}%`,
                            },
                            {
                              name: "priceValueNormalized",
                              field: "VALOR VENDA",
                              viewList: true,
                            },
                            {
                              name: "commissionValueNormalized",
                              field: "VALOR COMISSÃO",
                              viewList: true,
                            },
                          ]}
                        />
                      </Accordion>
                    ))}
                  </div>
                </Accordion>
              ))}
            </div>
          </Box>
        )}
        {getServiceInvoice?.data?.file_pdf &&
          getServiceInvoice?.data?.file_xml && (
            <Box style={{ marginBottom: "1rem" }}>
              <BoxHeading>Arquivos</BoxHeading>

              {[3].includes(getServiceInvoice?.data?.status.cod ?? 0) && (
                <Textarea
                  label="Motivo da recusa"
                  name="reason-value"
                  value={getServiceInvoice.data.reason}
                  readOnly
                />
              )}

              <ContainerPreviews
                style={{
                  overflowY: "auto",
                  maxHeight: "25vh",
                  marginBottom: "1rem",
                  display: "flex",
                  flexDirection: "row",
                  gap: "1rem",
                }}
              >
                <li
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    if (!!getServiceInvoice?.data?.file_pdf)
                      handleDownloadFile({
                        mimetype: getServiceInvoice.data.file_pdf.mimetype,
                        key: getServiceInvoice.data.file_pdf.key,
                        filename: getServiceInvoice.data.file_pdf.name,
                      });
                  }}
                >
                  <img src={icon_pdf} alt="icon_pdf" />
                  <div className="fileInfo">
                    <div>
                      <strong>{getServiceInvoice?.data?.file_pdf?.name}</strong>
                      <span>
                        {filesize(getServiceInvoice?.data?.file_pdf?.size ?? 0)}
                      </span>
                    </div>
                    <button type="button">
                      <MdOutlineFileDownload size={30} />
                    </button>
                  </div>
                </li>
                <li
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    if (!!getServiceInvoice?.data?.file_xml)
                      handleDownloadFile({
                        mimetype: getServiceInvoice.data.file_xml.mimetype,
                        key: getServiceInvoice.data.file_xml.key,
                        filename: getServiceInvoice.data.file_xml.name,
                      });
                  }}
                >
                  <img src={icon_xml} alt="icon_xml" />
                  <div className="fileInfo">
                    <div>
                      <strong>{getServiceInvoice?.data?.file_xml?.name}</strong>
                      <span>
                        {filesize(getServiceInvoice?.data?.file_xml?.size ?? 0)}
                      </span>
                    </div>
                    <button type="button">
                      <MdOutlineFileDownload size={30} />
                    </button>
                  </div>
                </li>
              </ContainerPreviews>
            </Box>
          )}

        {seller && [1, 3].includes(getServiceInvoice?.data?.status.cod ?? 0) && (
          <LayoutForm onSubmit={formik.handleSubmit}>
            <h3 style={{ marginBottom: "1.5rem" }}>Arquivos</h3>

            <GroupInput>
              <div className="field-group">
                <div className="field">
                  <div className="containerLabelButton">
                    <label htmlFor="pdf">Arquivo PDF</label>
                  </div>

                  <Dropzone
                    accept={["application/pdf"]}
                    onFileUploaded={(e) => {
                      formik.setFieldValue("invoicePdf", e[0]);
                      formik.handleBlur("invoicePdf");
                    }}
                  />
                  <ContainerPreviews
                    style={{
                      overflowY: "auto",
                      maxHeight: "25vh",
                    }}
                  >
                    {formik.values.invoicePdf && (
                      <li>
                        <img src={icon_pdf} alt="icon_pdf" />
                        <div className="fileInfo">
                          <div>
                            <strong>{formik.values.invoicePdf.name}</strong>
                            <span>
                              {filesize(formik.values.invoicePdf.size)}
                            </span>
                          </div>

                          <button
                            type="button"
                            onClick={() => {
                              formik.setFieldValue("invoicePdf", undefined);
                            }}
                          >
                            <IoMdTrash size={30} />
                          </button>
                        </div>
                      </li>
                    )}
                  </ContainerPreviews>

                  {formik.touched.invoicePdf && formik.errors.invoicePdf && (
                    <ContainerError>
                      <FiAlertCircle color="#f00" size={16} />
                      <span> {formik.errors.invoicePdf} </span>
                    </ContainerError>
                  )}
                </div>

                <div className="field">
                  <div className="containerLabelButton">
                    <label htmlFor="xml">Arquivo XML</label>
                  </div>

                  <Dropzone
                    accept={["application/xml", "text/xml"]}
                    onFileUploaded={(e) => {
                      formik.setFieldValue("invoiceXml", e[0]);
                      formik.handleBlur("invoiceXml");
                    }}
                  />
                  <ContainerPreviews
                    style={{
                      overflowY: "auto",
                      maxHeight: "25vh",
                    }}
                  >
                    {formik.values.invoiceXml && (
                      <li>
                        <img src={icon_xml} alt="icon_xml" />
                        <div className="fileInfo">
                          <div>
                            <strong>{formik.values.invoiceXml.name}</strong>
                            <span>
                              {filesize(formik.values.invoiceXml.size)}
                            </span>
                          </div>

                          <button
                            type="button"
                            onClick={() => {
                              formik.setFieldValue("invoiceXml", undefined);
                            }}
                          >
                            <IoMdTrash size={30} />
                          </button>
                        </div>
                      </li>
                    )}
                  </ContainerPreviews>

                  {formik.touched.invoiceXml && formik.errors.invoiceXml && (
                    <ContainerError>
                      <FiAlertCircle color="#f00" size={16} />
                      <span> {formik.errors.invoiceXml} </span>
                    </ContainerError>
                  )}
                </div>
              </div>
            </GroupInput>

            <ButtonSubmit disabled={loadingForm}>
              {loadingForm ? <Loading /> : "Enviar"}
            </ButtonSubmit>
          </LayoutForm>
        )}

        {admin &&
          (permission?.serviceInvoice ?? 0) > 2 &&
          [2].includes(getServiceInvoice?.data?.status.cod ?? 0) && (
            <LayoutForm onSubmit={formikApproval.handleSubmit}>
              <h3 style={{ marginBottom: "1.5rem" }}>Aprovação</h3>

              <ButtonCheck
                name="statusCod"
                options={[
                  { value: "4", field: "Aprovar", color: "#1b9f03" },
                  { value: "3", field: "Reprovar", color: "#d21e26" },
                ]}
                value={formikApproval.values.statusCod}
                setValue={(data) => {
                  formikApproval.setFieldValue("statusCod", data);
                }}
                error={
                  formikApproval.touched.statusCod &&
                  formikApproval.errors.statusCod
                    ? formikApproval.errors.statusCod
                    : undefined
                }
              />

              <Textarea
                label="Motivo"
                {...formikApproval.getFieldProps("reason")}
                error={
                  formikApproval.touched.reason && formikApproval.errors.reason
                    ? formikApproval.errors.reason
                    : undefined
                }
              />

              <ButtonSubmit disabled={loadingForm}>
                {loadingForm ? <Loading /> : "Enviar"}
              </ButtonSubmit>
            </LayoutForm>
          )}
      </CreateAndDetailLayout>

      <Modal
        modalVisible={modalSendInvoiceAlert}
        setModalVisible={setModalSendInvoiceAlert}
        headerOff={true}
        maxHeight={350}
        maxWidth={650}
      >
        <PopUpConfirmation>
          <div className="headerPopUp">
            <nav>
              <IoIosSend size={95} opacity={0.2} />
            </nav>
            <span>Você tem certeza que deseja, enviar o alerta ?</span>
          </div>
          <div className="buttonsPopUp">
            <button
              type="button"
              onClick={() => setModalSendInvoiceAlert(false)}
            >
              Cancelar
            </button>
            <button
              style={{ background: "rgb(2, 106, 166)" }}
              type="button"
              onClick={() =>
                loadingSendInvoiceAlert ? () => {} : handleSendInvoiceAlert()
              }
            >
              {!loadingSendInvoiceAlert ? (
                "Enviar"
              ) : (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignContent: "center",
                  }}
                >
                  <Loading
                    size={22}
                    borderSize={4}
                    colorLoading="rgba(255,255,255)"
                    borderColor="rgba(255,255,255, 0.3)"
                  />
                </div>
              )}
            </button>
          </div>
        </PopUpConfirmation>
      </Modal>
      <Modal
        modalVisible={modalApprovalAlert}
        setModalVisible={setModalApprovalAlert}
        headerOff={true}
        maxHeight={350}
        maxWidth={650}
      >
        <PopUpConfirmation>
          <div className="headerPopUp">
            <nav>
              <FaCheckCircle size={95} opacity={0.2} />
            </nav>
            <span>Você tem certeza que deseja aprovar ?</span>
          </div>
          <div className="buttonsPopUp">
            <button
              type="button"
              onClick={() => setModalSendInvoiceAlert(false)}
            >
              Cancelar
            </button>
            <button
              style={{ background: "#1b9f03" }}
              type="button"
              onClick={() =>
                loadingSendInvoiceAlert
                  ? () => {}
                  : handleApprovalInvoiceAlert()
              }
            >
              {!loadingSendInvoiceAlert ? (
                "Aprovar"
              ) : (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignContent: "center",
                  }}
                >
                  <Loading
                    size={22}
                    borderSize={4}
                    colorLoading="rgba(255,255,255)"
                    borderColor="rgba(255,255,255, 0.3)"
                  />
                </div>
              )}
            </button>
          </div>
        </PopUpConfirmation>
      </Modal>
    </>
  );
};

export default ServiceInvoice;
