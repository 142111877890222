import styled from "styled-components";
import { Form as FormDevolution } from "../create/styles";

export const Container = styled.div`
  position: relative;
  grid-area: APP;

  padding: 10px 30px 10px 30px;
  overflow-y: scroll;
  max-height: 100%;
  height: 100%;

  header {
    margin-bottom: 20px;
  }

  ::-webkit-scrollbar {
    width: 8px;
  }

  ::-webkit-scrollbar-thumb {
    background: #333;
    width: 4px;
  }

  ::-webkit-scrollbar-track {
    background: #888;
  }
`;

export const InfoStatus = styled.div`
  width: 100%;
  margin-bottom: 25px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;

  .containerStatus {
    position: relative;
    height: 72px;
    flex: 1;
  }

  .bannerLine {
    height: 5px;
    width: 100%;
    background-color: rgba(136, 227, 138, 0.5);
    border-radius: 4px 4px 0 0;
    position: absolute;
  }

  .containerStatus {
    margin-right: 8px;
    margin-bottom: 8px;
  }

  /* .containerStatus:last-child {
    margin-right: 0px;
  } */

  .box {
    flex: 1;
    min-width: 240px;
    height: 100%;
    padding: 8px 14px;
    border-radius: 4px;
    box-shadow: 0 0 4px 0px rgba(0, 0, 0, 0.3);
    background-color: #fff;

    div {
      padding: 5px 0;
      h5 {
        font-size: 12px;
        color: #333;
      }
    }

    p {
      font-size: 15px;
      font-weight: 500;
    }

    span {
      font-size: 13px;
      font-weight: 400;
      border-radius: 4px;
      padding: 2px 5px;
    }
  }

  @media (max-width: 900px) {
    flex-direction: column;

    .containerStatus {
      margin-left: 0;
      margin-bottom: 0px;
      margin-top: 10px;
    }
  }
`;

export const BoxFilter = styled.div`
  position: fixed;
  bottom: 2px;
  right: 2px;
  z-index: 3;

  .fixedButton {
    display: flex;
    justify-content: center;
    align-items: center;
    flex: 0.4;

    position: absolute;
    bottom: 10px;
    right: 20px;

    button {
      width: 50px;
      height: 50px;

      background-color: #333;
      border-radius: 100%;
      box-shadow: 0 0 5px 0px rgba(0, 0, 0, 0.5);

      transition: all 0.2s ease-in-out;

      &:hover {
        box-shadow: 0 0 8px 0px rgba(0, 0, 0, 0.8);
        transform: scale(1.1);
      }
    }
  }
`;

export const ContainerGraphics = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  align-items: flex-start;

  @media (max-width: 1100px) {
    flex-direction: column;
  }
`;

export const BoxGraphics = styled.div`
  max-width: 100%;
  /* height: 70vh; */
  width: 50%;

  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;

  margin-bottom: 25px;
  margin-top: 20px;

  @media (max-width: 1100px) {
    width: 100%;
  }

  h3 {
    margin-bottom: 20px;
  }
`;

export const TableContainer = styled.div`
  width: 100%;

  max-width: 100vw;
  overflow-y: auto;

  position: relative;
  border-collapse: collapse;

  table {
    width: 100%;
    border-collapse: collapse;
    border-spacing: 10px;

    thead {
      tr {
        th {
          z-index: 2;
          background-color: #eee;
          position: sticky;
          top: 0;
          padding: 6px 8px;
          font-size: 16px;
          color: #444;
          white-space: nowrap;
        }
      }
    }

    tbody {
      background-color: #fff;
      tr {
        color: #777;
        border-bottom: 2px solid #eee;
        cursor: pointer;

        &:hover {
          background-color: #eee;
        }

        td {
          position: relative;
          height: 35px;
          text-align: center;
          padding: 0 6px;

          .notification {
            display: flex;
            align-items: center;
            position: absolute;
            left: 8px;
            top: 0px;
            height: 100%;
          }
          .status {
            color: #000;
            font-size: 13px;
            font-weight: 500;
            border-radius: 4px;
            padding: 2px 5px;
            white-space: nowrap;
          }

          .stuationContainer {
            width: 100%;

            div {
              width: 40px;
              margin: 0 auto;
            }

            svg {
              transition: transform 0.2s ease-in-out;

              &:hover {
                transform: scale(1.3);
              }
            }
          }
        }
      }
    }
  }
`;

export const ContainerList = styled.div`
  border-radius: 4px;
  box-shadow: 0 0 2px rgba(0, 0, 0, 0.2);
  .headerTable {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 20px;

    height: 45px;
    background-color: #ccc;

    h3 {
      font-size: 18px;
      color: #111;
      font-weight: 500;
    }

    button {
      padding: 8px 10px;
      height: 28px;
      background-color: #d21e26;
      border-radius: 5px;

      display: flex;
      justify-content: space-between;
      align-items: center;
      transition: all 0.3s;

      &:hover {
        opacity: 0.6;
      }

      svg {
        color: #fff;
        margin-right: 2px;
      }

      span {
        font-size: 12px;
        color: #fff;
        font-weight: 500;
      }

      @media (max-width: 550px) {
        span {
          display: none;
        }
      }
    }
  }
`;

export const ContainerCheckbox = styled.div`
  position: relative;
  height: 100px;
  width: 100%;

  .titleCheckbox {
    position: absolute;
    background-color: #fff;
    top: -11px;
    left: 6px;

    margin-bottom: 4px;
  }

  .contentsCheckbox {
    border: 2px solid #ccc;
    padding: 6px;
    overflow: hidden;
    border-radius: 5px;

    overflow-y: scroll;
    height: 100%;

    .boxCheckbox {
      flex: 1;
      display: flex;
      align-items: center;
      flex-direction: row;
      margin-bottom: 4px;

      label {
        font-size: 14px;
        margin-left: 4px;
        margin-bottom: 0;
        color: #555;
      }

      input[type="checkbox"] {
        height: 16px;
        width: 16px;
      }

      @media (max-width: 550px) {
        input[type="checkbox"] {
          height: 20px;
          width: 20px;
        }
      }
    }
  }
`;

export const ModalBody = styled.div`
  padding: 10px 10px;
  width: 100%;
`;

export const Form = styled(FormDevolution)`
  overflow-x: unset;
  margin: 0;

  ::-webkit-scrollbar {
    width: none;
  }

  ::-webkit-scrollbar-thumb {
    width: none;
  }

  ::-webkit-scrollbar-track {
    background: none;
  }

  .field {
    input {
      cursor: auto;
      height: 35px;
    }
  }
`;

export const Error = styled.div`
  display: flex;
  align-items: center;

  padding: 5px 2px;
  span {
    margin-left: 4px;
    font-weight: 400;
    font-size: 14px;
    color: #f00;
  }
`;

export const FooterContainer = styled.div`
  height: 55px;
  width: 100%;
  padding: 10px 10px;
  margin-bottom: 20px;

  background-color: #fff;

  display: flex;
  justify-content: space-between;
  align-items: center;

  @media (max-width: 550px) {
    height: 80px;
  }

  span {
    padding-bottom: 10px;
    font-size: 12px;
    font-weight: 400;
  }

  div {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    @media (max-width: 500px) {
      flex-direction: column;
    }

    button {
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 4px;

      height: 30px;
      padding: 15px 15px;
      margin-right: 10px;

      font-weight: 700;
      background-color: #fff;
      transition: all 0.8s;
      color: #555;
    }

    button:hover {
      background-color: #ccc;
    }

    .buttonNull {
      cursor: not-allowed;
    }
  }
`;

export const ScreenLoading = styled.div`
  width: 100%;
  height: 100%;

  display: flex;
  align-items: center;
  justify-content: center;
`;

export const SlaContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  min-width: 100px;
  flex: 1;
  height: 100%;

  .boxSla {
    position: relative;
    overflow: hidden;

    height: 65%;
    max-height: 23px;
    width: 90%;

    background-color: #fbfbfb;
    border-radius: 8px;
    box-shadow: 0px 0px 2px 0px rgba(0, 0, 0, 0.2);

    .percentageSla {
      position: absolute;
      margin: auto;

      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      height: 100%;

      span {
        font-size: 12px;
        font-weight: 700;
        color: #444;
      }
    }

    .valueSla {
      height: 100%;
      width: 25%;

      /* background-color: rgba(255, 220, 0, 0.6); */
      /* background-color: rgba(255, 61, 61, 0.8); */
      background-color: rgba(90, 182, 95, 0.8);
    }
  }
`;

export const ExternalLink = styled.td`
  width: 100%;
  height: 100%;

  display: flex;
  justify-content: center;
  align-items: center;

  a {
    background-color: transparent;
    transition: 0.15s all;
    color: #333;

    &:hover {
      transform: scale(1.2);
    }
  }
`;

export const NoStyleLink = styled.a`
  width: 100%;
  height: 100%;

  text-decoration: none;
  color: inherit;
`;

export const ContainerBoxAnalytic = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  column-gap: 9px;
  row-gap: 9px;
  margin-bottom: 33px;
`;

export const BoxAnalytic = styled.div`
  flex: 1;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  background-color: #fff;
  padding: 1rem 1rem;
  min-width: 465px;

  position: relative;

  .navExternalLink {
    position: absolute;

    top: 7px;
    right: 7px;
  }

  .span-alert {
    font-weight: 400;
    font-size: 12px;

    a {
      color: #d21e26;
      font-weight: 700;

      &:hover {
        text-decoration: underline !important;
      }
    }
  }

  @media (max-width: 600px) {
    min-width: 100%;
  }
`;

export const SubTitle = styled.h2`
  font-weight: lighter;
  margin-top: 3rem;
`;
