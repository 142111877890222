import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { BoxAnalyticCharArea } from "../../../components/Analytic/BoxAnalyticCharArea";
import { BoxAnalyticCharBar } from "../../../components/Analytic/BoxAnalyticCharBar";
import { BoxAnalyticCharDonut } from "../../../components/Analytic/BoxAnalyticCharDonut";
import { BoxAnalyticCount } from "../../../components/Analytic/BoxAnalyticCount";
import { CreateAndDetailLayout } from "../../../components/CreateAndDetailLayout";
import { PanelAndDetailAndCreateHeader } from "../../../components/panel/PanelAndDetailAndCreateHeader";
import { Pagination } from "../../../components/panel/table/Pagination";
import { TableContent } from "../../../components/panel/table/TableContent";
import { TableHeader } from "../../../components/panel/table/TableHeader";
import { useRequestUpdateClient } from "../../../hook/queries/useRequestUpdateClient";
import { useRequestUpdateClientAnalytic } from "../../../hook/queries/useRequestUpdateClientAnalytic";
import { setQueryParams, useQueryParams } from "../../../hook/useQueryParams";
import { columns } from "../../panel/PanelRequestUpdateClient";
import { ContainerBoxAnalytic, SubTitle } from "../styles";

const APP_LINK =
  process.env.NODE_ENV !== "development"
    ? "http://portal.alpardobrasil.com.br"
    : "http://localhost:3000";

const RequestUpdatedClient = () => {
  const query = useQueryParams();
  const history = useHistory();
  const { data, isLoading } = useRequestUpdateClientAnalytic();

  const [page, setPage] = useState<number>(() => {
    const pageQuery = query.get("page");

    if (!isNaN(Number(pageQuery)) && Number(pageQuery) > 0)
      return Number(pageQuery);

    return 1;
  });

  const list = useRequestUpdateClient(page, {
    isFinished: "não",
    removeUserId: 1,
  });

  useEffect(() => {
    if (page > 0) {
      setQueryParams({
        data: {
          field: "page",
          value: String(page),
        },
        type: "set",
        history,
      });
    }
  }, [page, history]);

  return (
    <CreateAndDetailLayout isLoading={isLoading}>
      <PanelAndDetailAndCreateHeader
        title={"Análise cadastral de clientes"}
        goBack
      />

      <SubTitle>Dados internos</SubTitle>

      <ContainerBoxAnalytic style={{ marginTop: 24, marginBottom: 0 }}>
        <BoxAnalyticCount
          description="Total de clientes que entramos em contato"
          qtd={data?.internalData?.qtdTotalClientContact ?? 0}
          isLoading={isLoading}
          isNotColor
          minWidth={"auto"}
        />
      </ContainerBoxAnalytic>

      <ContainerBoxAnalytic style={{ marginTop: 9 }}>
        <BoxAnalyticCharDonut
          title="Comparação de exito no contato"
          values={[
            {
              name: "Sim",
              length: data?.internalData?.qtdIsClientContact ?? 0,
            },
            {
              name: "Não",
              length: data?.internalData?.qtdNotClientContact ?? 0,
            },
          ]}
        />

        <BoxAnalyticCharBar
          title="Ranking áreas"
          horizontal={false}
          descriptions={
            data?.internalData?.rakingPermission?.map(
              (reason) => reason.name
            ) ?? []
          }
          values={
            data?.internalData?.rakingPermission?.map((reason) => reason.qtd) ??
            []
          }
        />
        <BoxAnalyticCharBar
          title="Ranking ADM Vendas"
          horizontal={false}
          descriptions={
            data?.internalData?.rakingAdm?.map((reason) => reason.name) ?? []
          }
          values={
            data?.internalData?.rakingAdm?.map((reason) => reason.qtd) ?? []
          }
        />
      </ContainerBoxAnalytic>

      <SubTitle>Atualização cadastral</SubTitle>

      <ContainerBoxAnalytic style={{ marginTop: 24, marginBottom: 0 }}>
        <BoxAnalyticCount
          description="Total de solicitações"
          qtd={data?.requestUpdateClient.qtdTotal ?? 0}
          isLoading={isLoading}
          isNotColor
          obs="Pendentes e finalizadas"
          minWidth={"auto"}
        />

        <BoxAnalyticCount
          description="Solicitações pendentes"
          qtd={data?.requestUpdateClient.qtdPendency ?? 0}
          isLoading={isLoading}
          minWidth={"auto"}
        />
      </ContainerBoxAnalytic>

      <ContainerBoxAnalytic style={{ marginTop: 9, marginBottom: 0 }}>
        <BoxAnalyticCharDonut
          title="Tipos de usuários que solicitaram"
          values={data?.requestUpdateClient.userTypes ?? []}
        />

        <BoxAnalyticCharDonut
          title="Tipos de solicitação"
          values={data?.requestUpdateClient.requestTypes ?? []}
        />
      </ContainerBoxAnalytic>

      <ContainerBoxAnalytic style={{ marginTop: 9 }}>
        <BoxAnalyticCharArea
          title="Fluxo de solicitação"
          typeDate="number"
          dates={data?.requestUpdateClient.requestFlow.date ?? []}
          values={data?.requestUpdateClient.requestFlow.content ?? []}
          obs={<>* Range dos últimos 12 meses.</>}
        />

        <BoxAnalyticCharArea
          title="Prazo médio"
          dates={data?.requestUpdateClient?.mediumTerm.date ?? []}
          values={data?.requestUpdateClient?.mediumTerm.content ?? []}
          typeDate="number"
          obs={<>* Range dos últimos 12 meses.</>}
        />
      </ContainerBoxAnalytic>

      <TableHeader
        title={`Solicitações pendentes (${list.data?.totalCount ?? "-"})`}
        style={{ marginTop: "2rem" }}
      />

      <TableContent
        data={list?.data?.requestUpdateClients}
        columns={columns.filter((f) => f.viewList)}
        isLoading={list.isLoading}
        onClickRow={(row) => {
          window.open(`${APP_LINK}/admin/atualizacao-fotos/${row.id}`);
        }}
      />

      <Pagination
        totalCountOfRegisters={list.data?.totalCount ?? 0}
        currentPage={page}
        onPageChange={setPage}
      />
    </CreateAndDetailLayout>
  );
};

export default RequestUpdatedClient;
