import React, { ChangeEvent, useEffect, useState } from "react";

import { useFormik } from "formik";
import { useHistory } from "react-router-dom";
import { useToasts } from "react-toast-notifications";
import * as Yup from "yup";

import { IForecast, IForecastData, IResponseAPIForecast } from "..";

import { BtnAction, PopUpConfirmation } from "../../styles";

import { AiFillCheckCircle, AiFillExclamationCircle } from "react-icons/ai";
import { BsCheckCircle } from "react-icons/bs";
import { FaCheckCircle, FaEdit } from "react-icons/fa";
import { FiSend } from "react-icons/fi";
import { MdOutlineCancel } from "react-icons/md";
import { Input } from "../../../../components/Form/Input";
import { Select } from "../../../../components/Form/Select";
import Modal from "../../../../components/ModalLib";
import { PanelFilter } from "../../../../components/panel/PanelFilter";
import { Pagination } from "../../../../components/panel/table/Pagination";
import {
  FieldProps,
  TableContent,
} from "../../../../components/panel/table/TableContent";
import { TableHeader } from "../../../../components/panel/table/TableHeader";
import api from "../../../../service/api";

interface PanelSendsProps {
  admin?: boolean;
  is_manager?: boolean;
  is_supervisor?: boolean;

  setUpdateTotal: React.Dispatch<React.SetStateAction<string>>;
  setData: React.Dispatch<React.SetStateAction<IForecast>>;

  category: {
    apparel: boolean;
    equipment: boolean;
    footwear: boolean;
  };

  data: {
    id: number;
    finished: boolean;

    historic1: string;
    historic2: string;
    historic3: string;
    historic4: string;

    forecast_seller: {
      id: number;
      finished: boolean;
      finished_at: Date | null;
    };

    forecast_data: IForecastData[];
  };
}

interface ChangeRowProps {
  id: number;
  apparel?: number;
  equipment?: number;
  footwear?: number;
  reason?: string;
}

interface IPagination {
  total: number;
  limit: number;
  page: number;
  pages: number;
}

interface IFilter {
  codClient?: number;
  cnpj?: string;
  company_name?: string;
}

export const PanelClients: React.FC<PanelSendsProps> = ({
  category,
  data,

  setUpdateTotal,
  setData,
}) => {
  const columns: FieldProps<IForecastData>[] = [
    {
      name: "action",
      field: "",
      tdStyles: () => ({ width: 50 }),
      viewList: true,
      render: (row) => {
        return changeRow?.id !== row.id ? (
          <button
            type="button"
            style={{ background: "transparent", color: "#444" }}
            onClick={() => {
              setChangeRow({
                id: row.id,
                footwear: row.footwear,
                equipment: row.equipment,
                apparel: row.apparel,
                reason: row.reason,
              });
            }}
          >
            <FaEdit size={24} />
          </button>
        ) : (
          <div style={{ display: "flex", flexDirection: "column" }}>
            <BtnAction
              style={{ marginBottom: 2, background: "#259457" }}
              onClick={handleChangeRow}
            >
              <BsCheckCircle fontSize={20} />
            </BtnAction>
            <BtnAction onClick={() => setChangeRow(undefined)}>
              <MdOutlineCancel fontSize={22} />
            </BtnAction>
          </div>
        );
      },
    },
    {
      name: "status",
      field: "",
      tdStyles: () => ({ width: 50 }),
      viewList: true,
      render: (row) => {
        return !row.isPendecy ? (
          <AiFillCheckCircle size={24} color={"#009c27"} />
        ) : (
          <AiFillExclamationCircle size={24} color={"#f7a81e"} />
        );
      },
    },
    {
      name: "client",
      field: "Cliente",
      tdStyles: () => ({ width: "15vw" }),
      viewList: true,
      render: (row) => (
        <div style={{ minWidth: "12rem", width: "15vw" }}>
          <p
            style={{
              fontWeight: "bold",
              fontSize: "0.875rem",

              maxWidth: "95%",
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
            }}
          >
            {`${row.client_pj?.cod} - ${row.client_pj?.company_name}`}
          </p>
          <span
            style={{ fontWeight: "lighter", fontSize: "0.8rem" }}
          >{`${row.client_pj?.adresses?.city} - ${row.client_pj?.adresses?.uf}`}</span>
        </div>
      ),
    },
    {
      name: "historic",
      field: "HISTÓRICO",
      tdStyles: () => ({ width: 140 }),
      viewList: true,
      render: (row) => (
        <div style={{ padding: "3px 0" }}>
          <div
            style={{
              whiteSpace: "nowrap",
            }}
          >
            {data?.historic1}:{" "}
            <b>
              {Number(row.client_pj?.forecastHistoric.historic1).toLocaleString(
                "pt-br",
                {
                  style: "currency",
                  currency: "BRL",
                }
              )}
            </b>
          </div>
          <div
            style={{
              whiteSpace: "nowrap",
            }}
          >
            {data?.historic2}:{" "}
            <b>
              {Number(row.client_pj?.forecastHistoric.historic2).toLocaleString(
                "pt-br",
                {
                  style: "currency",
                  currency: "BRL",
                }
              )}
            </b>
          </div>
          <div
            style={{
              whiteSpace: "nowrap",
            }}
          >
            {data?.historic3}:{" "}
            <b>
              {Number(row.client_pj?.forecastHistoric.historic3).toLocaleString(
                "pt-br",
                {
                  style: "currency",
                  currency: "BRL",
                }
              )}
            </b>
          </div>
          <div
            style={{
              whiteSpace: "nowrap",
            }}
          >
            {data?.historic4}:{" "}
            <b>
              {Number(row.client_pj?.forecastHistoric.historic4).toLocaleString(
                "pt-br",
                {
                  style: "currency",
                  currency: "BRL",
                }
              )}
            </b>
          </div>
        </div>
      ),
    },
    {
      name: "historic1",
      tdStyles: () => ({ width: 100 }),
      field: data?.historic1 ?? "Histórico 1",
      viewList: false,
      render: (row) =>
        Number(row.client_pj?.forecastHistoric.historic1).toLocaleString(
          "pt-br",
          {
            style: "currency",
            currency: "BRL",
          }
        ),
    },
    {
      name: "historic2",
      tdStyles: () => ({ width: 100 }),
      field: data?.historic2 ?? "Histórico 2",
      viewList: false,
      render: (row) =>
        Number(row.client_pj?.forecastHistoric.historic2).toLocaleString(
          "pt-br",
          {
            style: "currency",
            currency: "BRL",
          }
        ),
    },
    {
      name: "historic3",
      tdStyles: () => ({ width: 100 }),
      field: data?.historic3 ?? "Histórico 3",
      viewList: false,
      render: (row) =>
        Number(row.client_pj?.forecastHistoric.historic3).toLocaleString(
          "pt-br",
          {
            style: "currency",
            currency: "BRL",
          }
        ),
    },
    {
      name: "historic4",
      tdStyles: () => ({ width: 100 }),
      field: data?.historic4 ?? "Histórico 4",
      viewList: false,
      render: (row) =>
        Number(row.client_pj?.forecastHistoric.historic4).toLocaleString(
          "pt-br",
          {
            style: "currency",
            currency: "BRL",
          }
        ),
    },
    {
      name: "footwear",
      field: "FTW",
      viewList: true,
      tdStyles: () => ({
        width: "6rem",
      }),
      render: (row) => (
        <Input
          readOnly={changeRow?.id !== row.id}
          name="footwear"
          styleInput={{
            width: "6rem",
            fontSize: "0.865rem",
            flex: 0,
            minHeight: 35,
          }}
          styleField={{
            marginBottom: "0",
            minWidth: 0,
          }}
          value={Number(
            changeRow?.id === row.id ? changeRow.footwear : row.footwear
          ).toLocaleString("pt-br", {
            style: "currency",
            currency: "BRL",
          })}
          onChange={handleInputChange}
        />
      ),
    },
    {
      name: "apparel",
      field: "APP",
      viewList: true,
      tdStyles: () => ({
        fontWeight: "bold",
        fontSize: "0.785rem",
        width: 100,
      }),
      render: (row) => (
        <Input
          readOnly={changeRow?.id !== row.id}
          name="apparel"
          styleInput={{
            width: "6rem",
            fontSize: "0.865rem",
            flex: 0,
            minHeight: 35,
          }}
          styleField={{
            marginBottom: "0",
            minWidth: 0,
          }}
          value={Number(
            changeRow?.id === row.id ? changeRow.apparel : row.apparel
          ).toLocaleString("pt-br", {
            style: "currency",
            currency: "BRL",
          })}
          onChange={handleInputChange}
        />
      ),
    },
    {
      name: "equipment",
      field: "EQ",
      viewList: true,
      tdStyles: () => ({
        fontWeight: "bold",
        fontSize: "0.785rem",
        width: 100,
      }),
      render: (row) => (
        <Input
          readOnly={changeRow?.id !== row.id}
          name="equipment"
          styleInput={{
            width: "6rem",
            fontSize: "0.865rem",
            flex: 0,
            minHeight: 35,
          }}
          styleField={{
            marginBottom: "0",
            minWidth: 0,
          }}
          value={Number(
            changeRow?.id === row.id ? changeRow.equipment : row.equipment
          ).toLocaleString("pt-br", {
            style: "currency",
            currency: "BRL",
          })}
          onChange={handleInputChange}
        />
      ),
    },
    {
      name: "total",
      field: "TOTAL",
      viewList: true,
      tdStyles: () => ({ fontWeight: "bold", fontSize: "0.9rem", width: 100 }),
      render: (row) =>
        Number(
          changeRow?.id === row.id
            ? Number(changeRow.equipment) +
                Number(changeRow.apparel) +
                Number(changeRow.footwear)
            : Number(row.equipment) + Number(row.apparel) + Number(row.footwear)
        ).toLocaleString("pt-br", {
          style: "currency",
          currency: "BRL",
        }),
    },
    {
      name: "reason",
      field: "MOTIVO",
      viewList: true,
      tdStyles: () => ({ width: 10 }),
      render: (row) => (
        <Select
          disabled={
            changeRow?.id !== row.id
              ? true
              : Number(changeRow.equipment) +
                  Number(changeRow.apparel) +
                  Number(changeRow.footwear) >
                0
          }
          name="reason"
          style={{
            background: "transparent",
            border: "2px solid #aaa",
            borderRadius: "5px",
            width: "7rem",
            minHeight: 35,
          }}
          value={changeRow?.id === row.id ? changeRow.reason : row.reason}
          data={[
            { name: "Inadimplente", value: "Inadimplente" },
            {
              name: "Cliente de Outra Zona",
              value: "Cliente de Outra Zona",
            },
            { name: "Cliente fechou", value: "Cliente fechou" },
            { name: "Troca de CNPJ", value: "Troca de CNPJ" },
            {
              name: "Cliente Vai Pular Coleção",
              value: "Cliente Vai Pular Coleção",
            },
            {
              name: "Cliente Sem Limite",
              value: "Cliente Sem Limite",
            },
            {
              name: "Cliente Somente A Vista Antecipado",
              value: "Cliente Somente A Vista Antecipado",
            },
          ]}
          onChange={handleInputChange}
        />
      ),
    },
    { field: "Pedente", name: "pendecy" },
  ];

  const { addToast } = useToasts();
  const history = useHistory();

  const [filters, setFilters] = useState<IFilter>({} as IFilter);
  const [viewFilter, setViewFilter] = useState(false);

  const [page, setPage] = useState<number>(1);
  const [pagination, setPagination] = useState<IPagination>({} as IPagination);

  const [update, setUpdate] = useState(1);
  const [loading, setLoading] = useState(false);

  const [changeRow, setChangeRow] = useState<ChangeRowProps>();
  const [confirmationFinished, setConfirmationFinished] =
    useState<boolean>(false);

  const formikFilter = useFormik({
    initialValues: {
      client: "",
    },
    validationSchema: Yup.object({}),
    onSubmit: (data) => {
      handleFilter(data as any);
    },
  });

  useEffect(() => {
    // setLoading(true);

    (async () => {
      if (Boolean(data.id)) {
        try {
          setLoading(true);

          const forecastDB = await api.get<IResponseAPIForecast>(
            `/forecast/seller/${data.id}`,
            {
              params: { limitRequest: 10, pageRequest: page - 1, ...filters },
            }
          );

          if (!Boolean(forecastDB.data)) {
            return history.push("/404");
          }

          const {
            contents,
            limit,
            page: pageResponse,
            pages,
            total,
          } = forecastDB.data;

          setData(contents);
          setPagination({ limit, page: pageResponse, pages, total });
          setLoading(false);
        } catch (error) {
          return history.push("/404");
        }
      }
    })();
    // eslint-disable-next-line
  }, [data.id, page, filters, update]);

  async function handleFinishForecastSeller() {
    setConfirmationFinished(false);

    let input_empty = false;

    const finish = await api.get<boolean>(
      `/forecast/seller/finish/${data.forecast_seller.id}`
    );

    input_empty = finish.data;

    if (input_empty) {
      return addToast(
        "Todos campos devem ser preenchidos para finalizar o forecast.",
        {
          appearance: "warning",
          autoDismiss: true,
        }
      );
    }

    await api.put(`/forecast/forecastseller/${data.forecast_seller.id}`, {
      status: 2,
      finished: true,
      finished_at: Date.now(),
      review: 0,
    });

    addToast("Envio de forecast realizada com sucesso.", {
      appearance: "success",
      autoDismiss: true,
    });

    return history.push("/representante/previsoes");
  }

  async function handleChangeRow() {
    if (!changeRow) return;

    if (
      !changeRow?.reason &&
      Number(
        (changeRow?.apparel ?? 0) +
          (changeRow?.equipment ?? 0) +
          (changeRow?.footwear ?? 0)
      ) <= 0
    ) {
      return addToast(
        "Necessário informar motivo pois valor total esta zerado.",
        {
          appearance: "warning",
          autoDismiss: true,
        }
      );
    }

    try {
      await api.put(`/forecast/forecastdata/${changeRow.id}`, changeRow);

      setUpdate(update + 1);

      // const normalized = data.forecast_data.map((item) =>
      //   item.id === changeRow.id
      //     ? {
      //         ...item,
      //         ...changeRow,
      //       }
      //     : item
      // );
      // setData((oldData) => ({ ...oldData, forecast_data: normalized }));
      setChangeRow(undefined);
      setUpdateTotal(String(Math.random()));

      return addToast("Alteração salva.", {
        appearance: "success",
        autoDismiss: true,
      });
    } catch (error) {}
  }

  function handleInputChange(event: ChangeEvent<HTMLInputElement>) {
    if (!changeRow) return;

    const { name, value } = event.target;

    let valueToNumber = Number(value.replace(/\D/g, "")) / 100;
    let nameInput = name as "footwear" | "equipment" | "apparel" | "reason";

    setChangeRow({
      ...changeRow,
      reason: valueToNumber > 0 ? "" : changeRow.reason,
      [nameInput]: nameInput === "reason" ? value : valueToNumber,
    });
  }

  function handleFilter(filter: IFilter) {
    setFilters({
      ...filter,
      company_name: filter.company_name
        ? filter.company_name.toUpperCase()
        : undefined,
    });

    setFilters(filter);
    setPage(1);
    setViewFilter(false);
  }

  return (
    <>
      <PanelFilter
        filters={filters}
        setFilters={setFilters}
        columns={columns}
        onSubmit={formikFilter.handleSubmit}
        onResetForm={formikFilter.resetForm}
        setViewFilter={setViewFilter}
        viewFilter={viewFilter}
      >
        <Input label="Cliente" {...formikFilter.getFieldProps("client")} />

        <Select
          label="Pedente"
          data={[
            {
              name: "Sim",
              value: "Sim",
            },
            {
              name: "Não",
              value: "Não",
            },
          ]}
          {...formikFilter.getFieldProps("pendecy")}
        />
      </PanelFilter>

      <TableHeader title="Clientes">
        {!data.finished && (
          <button
            style={{ background: "#1aa33f" }}
            onClick={() => setConfirmationFinished(true)}
            type="button"
          >
            <FaCheckCircle />
            <span>Finalizar Forecast</span>
          </button>
        )}
      </TableHeader>

      <TableContent<IForecastData>
        disabledHover
        textAlign="start"
        isLoading={loading}
        data={data.forecast_data ?? []}
        // height="60vh"
        columns={columns
          .filter((f) => f.viewList)
          .filter((f) => (!data.finished ? true : f.name !== "action"))
          .filter((f) => (category.footwear ? true : f.name !== "footwear"))
          .filter((f) => (category.apparel ? true : f.name !== "apparel"))
          .filter((f) => (category.equipment ? true : f.name !== "equipment"))}
      />

      <Pagination
        totalCountOfRegisters={pagination.total}
        currentPage={page}
        onPageChange={setPage}
        registersPerPage={10}
      />

      {confirmationFinished && (
        <Modal
          title="Selecione o cliente"
          modalVisible={confirmationFinished}
          setModalVisible={setConfirmationFinished}
          headerOff={true}
          maxHeight={350}
          maxWidth={650}
        >
          <PopUpConfirmation>
            <div className="headerPopUp">
              <nav>
                <FiSend size={95} opacity={0.2} />
              </nav>
              <span>
                Você tem certeza que deseja finalizar o forecast? Após o envio
                os dados não poderão ser alterados.
              </span>
            </div>
            <div className="buttonsPopUp">
              <button
                type="button"
                onClick={() => setConfirmationFinished(false)}
              >
                Cancelar
              </button>
              <button type="button" onClick={handleFinishForecastSeller}>
                Finalizar
              </button>
            </div>
          </PopUpConfirmation>
        </Modal>
      )}
    </>
  );
};
